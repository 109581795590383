/*
=====================================================================
*   Woo v1.0 Default Stylesheet
*   url: styleshout.com
*   05-02-2014
=====================================================================

TOC:

a. Webfonts and Icon fonts
b. Preloader
c. Reset
d. Default Styles
   1. Basic
   2. Typography
   3. Links
   4. Media
   5. Buttons
   6. Forms
   d. Grid
   e. Others
   1. Clearing
   2. Misc

=====================================================================  */

/* ------------------------------------------------------------------ */
/* a. Webfonts and Icon fonts
 ------------------------------------------------------------------ */

/* ------------------------------------------------------------------ */
/* d. Grid
--------------------------------------------------------------------- */

/* default
--------------------------------------------------------------- */
.row {
   width: 100%;
   padding:120px 0;

       &.white {
           background:#fff
       }
       &.grey {
           background:#f9f9f9
       }

       &.no-padding {
         padding:0
       }

       &.no-padding-top {
         padding-top:0
       }
}

.inner {
    width: 96%;
    max-width: 1140px;
    margin: 0 auto;
}
/* fixed width for IE8 */
.ie .row { width: 1000px ; }

.narrow .row { max-width: 980px; }

.row .row { width: auto; max-width: none; margin: 0 -18px; }

/* row clearing */
.row:before,
.row:after {
    content: " ";
    display: table;
}
.row:after {
    clear: both;
}

.column, .columns {
   position: relative;
   padding: 0 18px;
   min-height: 1px;
   float: left;
}
.column.centered, .columns.centered  {
    float: none;
    margin: 0 auto;
}

/* removed gutters */
.row.collapsed > .column,
.row.collapsed > .columns,
.column.collapsed, .columns.collapsed  { padding: 0; }

[class*="column"] + [class*="column"]:last-child { float: right; }
[class*="column"] + [class*="column"].end { float: right; }

/* column widths */
.row .one         { width: 8.33333%; }
.row .two         { width: 16.66667%; }
.row .three       { width: 25%; }
.row .four        { width: 33.33333%; }
.row .five        { width: 41.66667%; }
.row .six         { width: 50%; }
.row .seven       { width: 58.33333%; }
.row .eight       { width: 66.66667%; }
.row .nine        { width: 75%; }
.row .ten         { width: 83.33333%; }
.row .eleven      { width: 91.66667%; }
.row .twelve      { width: 100%; }

/* screenwidth less than 768px - mobile/smaller tablets
--------------------------------------------------------------- */
@media only screen and (max-width: 767px) {

   .row {
     width: 480px;
     margin: 0 auto;
      padding: 0;
  }
   .column, .columns {
     width: auto !important;
     float: none;
     margin-left: 0;
     margin-right: 0;
      padding: 0 30px;
   }
   .row .row { width: auto; max-width: none; margin: 0 -30px; }

   [class*="column"] + [class*="column"]:last-child { float: none; }
   [class*="bgrid"] [class*="column"] + [class*="column"]:last-child { float: none; }

   /* Offsets */
   .row .offset-1    { margin-left: 0%; }
   .row .offset-2    { margin-left: 0%; }
   .row .offset-3    { margin-left: 0%; }
   .row .offset-4    { margin-left: 0%; }
   .row .offset-5    { margin-left: 0%; }
   .row .offset-6    { margin-left: 0%; }
   .row .offset-7    { margin-left: 0%; }
   .row .offset-8    { margin-left: 0%; }
   .row .offset-9    { margin-left: 0%; }
   .row .offset-10   { margin-left: 0%; }
   .row .offset-11   { margin-left: 0%; }
}

/* screenwidth less than or equal 480px - mobile wide
--------------------------------------------------------------- */
@media only screen and (max-width: 480px) {

   .row { width: auto; }

}

/* larger screens
--------------------------------------------------------------- */
@media screen and (min-width: 1200px) {

   .wide .row { max-width: 1180px; }

}