@import "variables.less";
@import "default.less";
@import "layout.less";

.browser-mockup {
  border-top: 30px solid #eee;
  position: relative;
  border-radius: 3px 3px 0 0;
}

.browser-mockup:before {
  display: block;
  position: absolute;
  content: '';
  top: -1.25em;
  left: 1em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #f44;
  box-shadow:0 0 100px rgba(0,0,0,.22)
}

.browser-mockup > * {
  display: block;
}

/* Custom code for the demo */

.browser-mockup {
  	margin: 2em;
  	flex: 1;
  	img {
 		width: 100%;
	}	
}