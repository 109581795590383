html.login{

	body.with-navbar {
		padding-top:0
    }
    
    header {
        .uk-container {
            box-sizing: border-box;
        }
    }

	#login-wrap {
		width:100%;
		margin:0;
		z-index:2;
		position:relative;
         background:@blue url(https://feelbettr.com/assets/img/bg-1.jpg);
    	background-size:cover;
    	color:#fff;
    	height:100%;
    	padding:0 0 100px;
    }

    form a {
		color:#fff;

		&:hover {
			color:@secondary
		}
	}

	label {
		color:#fff;
		font-size:12px;
	}

	.intro {
		margin-bottom:30px
	}

	.checkbox {
		margin-top:0;
		padding-top:0
	}

	[type="checkbox"] {
		width:auto;
	}

	input {
		margin-top:0;
	}

    .login-wrap-inner {
        width:600px;
        max-width:90%;
        margin:0 auto;
        padding:100px 0 60px;

        @media screen and (max-height:700px) {
        	padding-top:30px;
        }
        position:relative;
        z-index:2;
    }

	.zg-overlay {
		height:100%;
		width:100%;
		top:0;
		left:0;
		position:absolute;
		background:rgba(54, 121, 166, 0.93);
		z-index:1;
	}

	h1,h2 {
		font-size: 1.8em;
		margin: 0;
		font-weight: 300;
		color:#fff;
	}

	header h1 {

		font-size: 5em;

		@media screen and (max-height:700px) {
			font-size: 4em;
		}
		
	    font-weight: 300;
	    line-height: 1em;
	    padding: 0;
	    margin: 0 0 10px;
	    color:#fff;
	    font-family: "mahalia",cursive;

	}

	form {
        button,.btn {
            background:@dblue;
            border:0;
            border-radius:3px;
            font-size:14px;
            text-transform:uppercase;
            color:#fff;
            transition:0.3s all;
            &:hover {
                background:lighten(@dblue, 8%);
            }
        }
    }

	label {
		line-height:1em
	}

	input {
			max-width:100%
		}
	
		input[type="password"],
		input[type="email"],
		input[type="text"] {
			// padding:10px !important;
			height:60px;

			border:1px solid #fff;

			@media screen and (min-height:700px) {
				height:60px;
			}
			line-height:3em;
			box-shadow: inset 100px 100px #fff;

			&:focus {
				color:@fblue;
			}
		}

		[type="submit"] {
			height:60px;
			border-radius:2px;
			width:100%
		}

		::-webkit-input-placeholder {
		   color: #999;
		}

		:-moz-placeholder { /* Firefox 18- */
		   color: #999;
		}

		::-moz-placeholder {  /* Firefox 19+ */
		   color: #999;
		}

		:-ms-input-placeholder {
		   color: #999;
		}

		input {
			font-size:14px
		}

		[type="checkbox"] {
			width:12px;
			height:12px;
			background-color:#fff;
			margin-right:10px;
			border:1px solid #fff;
			box-shadow:0
		}

		.btn {
			border:0;
		}

}