

/*
=====================================================================
*   Woo v1.0 Layout Stylesheet
*   url: styleshout.com
*   05-02-2014
=====================================================================

   TOC:
   a. General and Common Styles
   b. Header Styles
   c. Hero Section
   d. Features Section
   e. Pricing Section
   f. Screenshots Section
   g. Call To Action Section
   h. Testimonials Section
   i. Subscribe Section
   j. Footer
   k. lightbox

   todo: check bug in mobile z-index

===================================================================== */

/* ------------------------------------------------------------------ */
/* a. General and Common Styles
/* ------------------------------------------------------------------ */

/* ------------------------------------------------------------------ */
/* b. Header Styles
/* ------------------------------------------------------------------ */

header {
   height: 60px;
   width: 100%;
   border-bottom:1px solid rgba(0,0,0,0.05);
   position: fixed;
   left: 0;
   top: 0;
   z-index: 990;
   transition:0.3s background, 0.8s padding;

   .mobile-btn {
        margin-top:18px !important;
        margin-right:45px !important
   }
  
  &.has-scrolled {
    background: #fff;
    color:@text-color;
    padding:0 !important;
    ul#nav li a {
        color:@text-color;
     }

     .mobile-btn {
         * {
             color:@text-color !important
         }
     }
  }

  #register-menu {
      a {
          border:0
      }
  }
}

.step-by-step {
    width:600px;
    margin:80px auto 0;

    @grey: #f1f1f1;

    i {
        width:60px;
        height:60px;
        border-radius:100%;
        line-height:60px;
        background:@grey;
        text-align:center;
        display:inline-block;
        position:relative;
        z-index:2

    }

    .step {
        position:relative;

        &.step-right:after {
            right: -97px;
            height:2px;
            width:160px;
            position: absolute;
            z-index: 10;
            top: 30px;
            content:"";
            background: @grey;
        }

        &.step-left:after {
            left: -90px;
            height:2px;
            width:160px;
            position: absolute;
            z-index: 10;
            top: 30px;
            content:"";
            background: @grey;
        }
    }

    .green {
        i {
            background:@fgreen;
            color:#fff
        }

        // &.step-right:before,
        &.step-right:after {
            background:@fgreen;
        }
    }
}

/* header logo */
header .logo {
   margin-left: 36px;

   @media screen and (max-width:680px) {
       margin-left:0
   }
   margin-right: 20px;
   margin-top: 13px;
   float: left;
   width: auto;
   z-index: 991;

   /* add position relative since z-index only applies to
   elements that have been given an explicit position */
   position: relative;
}
header .logo a {
   display: block;
   margin: 0;
  padding: 0;
  border: none;
  outline: none;
   width: 130px;
   height: 18px;

}

/* header social */
header .header-social {
  font-size: 20px;
   font-weight: normal;
   line-height: 54px;
   color: #424a56;
   margin: 0 10px 0 0;
   padding: 0;
   float: right;
}
header .header-social li {
   display: inline-block;
   margin-right: 20px;
}
header .header-social li a { color: #fff; }
header .header-social li a:hover { color: #11ABB0; }

/* primary navigation
--------------------------------------------------------------------- */
#nav-wrap, #nav-wrap ul, #nav-wrap li, #nav-wrap a {
   margin: 0;
   padding: 0;
   border: none;
   outline: none;
}

/* nav-wrap */
#nav-wrap {
   font: 11px 'montserrat-regular', sans-serif;
   text-transform: uppercase;
   letter-spacing: 1.5px;
   float: left;
   margin-right:30px;
   width:100%
}

/* hide toggle button */
// #nav-wrap > a { display: none; }

ul#nav {
   min-height: 54px;
   width: auto;

   /* left align the menu */
   text-align: left;
}
ul#nav li {
   position: relative;
   list-style: none;
   height: 54px;
   display: inline-block;

}

/* Links */
ul#nav li a {
 padding-top:10px;
   /* 8px padding top + 8px padding bottom + 38px line-height = 54px */
   display: inline-block;
   padding: 8px 8px;
   line-height: 48px;
  text-decoration: none;
   text-align: left;
   color: #fff;

  -webkit-transition: color .2s ease-in-out;
  -moz-transition: color .2s ease-in-out;
  -o-transition: color .2s ease-in-out;
  -ms-transition: color .2s ease-in-out;
  transition: color .2s ease-in-out;

    &:hover {
        color:#333
    }
}

#nav li.uk-active a {
  color:#000
}

#nav li a:hover {
  color:#000
}

ul#nav li a:active { background-color: transparent !important; }
ul#nav li.current a { color: #fff; }

.default-page {
    ul#nav li a {
        color:#222
    }
}

#content {
    background:#fff;
}

.container {
    max-width:1140px;
    padding-top:100px;
    margin:0 auto;
    width:90%
}

.fb-blog {
    .container {
        max-width:800px;
        padding:0px 30px 100px;
        margin-top:-260px;
        position:relative;
        z-index:10;
        background:#fff;
        box-shadow:0 0 4px 4px rgba(0,0,0,0.04);
    }

    #hero {
        min-height:400px
    }

    .hero-text {
        text-align:left;
        position:relative;
        z-index:100;
        h1 {
            font-size:10em;
            margin:0px 0 40px 30px;
            font-weight:600;
            color:rgba(0,0,0,0.4);
        }
    }

    .blog-item-image {
        height:300px;
        background-position:center;
        background-size:cover;
        background-repeat:no-repeat;
        margin:0 -30px 30px;

        img {
            width:100%
        }
    }

    .blog-sidebar {
        font-size:12px
    }

    .blog-date-day {
        font-size:3em
    }

    .blog-item-date {
        padding:10px;
        background:#fafafa
    }

    .blog-tag a{
        background:#fafafa;
        color:#999;
        padding:8px;
        border-radius: 3px;
        text-transform:uppercase;
        font-size:11px;
    }

    h1 {
        a {
            color:@text-color
        }
    }
}

.float-right {
    float:right;
}

a.btn {
    border:1px solid rgba(0,0,0,0.08);
    color:@text-color;
    text-transform: uppercase;
    font-size:12px;
    padding:0 12px;
    border-radius:3px;
    text-align:center;

}
/* ------------------------------------------------------------------ */
/* c. Hero Section
/* ------------------------------------------------------------------ */

#hero,html.fb-subpage .is-bg-dark,#subscribe,.green-row,.dark-row {
   padding: 0px 0 60px;
   background:@blue url(https://feelbettr.com/assets/img/bg-1.jpg) no-repeat !important;
   background-size:cover !important;
   overflow: hidden;
   position:relative;

   &:after {
       background:fade(@blue, 93%);
       height:100%;
       content:"";
       width:100%;
       position:absolute;
       top:0;
       left:0;
       z-index:1
   }

   .button {
      background:none;
      border:1px solid #fff;
        padding:10px;

      &:hover {
        background:rgba(0,0,0,0.3);
      }
   }
}

#hero .row {  position: relative;z-index:2}

/* Hero Text */
.hero-text {

   width: 83%;
   text-align: center;
   margin: 0 auto;
}
.hero-text h1 {
   font: 40px/1.2em;
   color: #fff;
   padding: 0;
   margin: 0;
}
.hero-text h1 span,
.hero-text a { color: #fff; }
.hero-text p {
   font: 16px/30px;
   padding: 0;
   margin: 12px 8% 0;
   color:#fff;
}

/* Hero Image */
.hero-image {
   width: 100%;
   margin: 0 auto;
}
.hero-image img {
   vertical-align: bottom;
   display: block;
}

/* Hero CTA Buttons */
#hero .buttons {
   text-align: center;
   margin: 0 0 18px 0;
}
#hero .buttons .button {

   text-transform : uppercase;
   letter-spacing: 2px;
}

/* ------------------------------------------------------------------ */
/* j. Footer
/* ------------------------------------------------------------------ */

footer {
    padding-top:80px;
   p {
    margin-top:0;
    margin-left:12px
   }

   h3 {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold
   }
}

