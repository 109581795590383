@import "./../../../../node_modules/uikit/src/less/uikit.theme.less";
@import "../feelbettr/variables.less";

@import "../feelbettr/_login.less";
@import "style/styles.less";
@import "../themes/shared.less";

@internal-form-select-image:                     "~uikit/src/images/backgrounds/form-select.svg";
@internal-form-radio-image:                      "~uikit/src/images/backgrounds/form-radio.svg";
@internal-form-checkbox-image:                   "~uikit/src/images/backgrounds/form-checkbox.svg";
@internal-form-checkbox-indeterminate-image:     "~uikit/src/images/backgrounds/form-checkbox-indeterminate.svg";
@internal-divider-icon-image:                   "~uikit/src/images/backgrounds/divider-icon.svg";
@internal-list-bullet-image:                    "~uikit/src/images/backgrounds/list-bullet.svg";
@internal-nav-parent-close-image:               "~uikit/src/images/backgrounds/nav-parent-close.svg";
@internal-nav-parent-open-image:                "~uikit/src/images/backgrounds/nav-parent-open.svg";
@internal-accordion-open-image:                "~uikit/src/images/backgrounds/accordion-open.svg";
@internal-accordion-close-image:               "~uikit/src/images/backgrounds/accordion-close.svg";

@dblue: darken(@blue, 10%);
@fblue: #3E90D1;
@brightblue: #3F90D0;

html, body, li, h4, h2, h1, h3 {
    font-family: "azo-sans-web";
}

h1 {
	font-weight:300
}

.is-wrapper {
	float:left;
	height:auto
}

.row {
	padding:0
}

.uk-text-lead {
	font-size:1.3rem;
	color:@text-color;

	strong {
		color:@text-color;
	}
}

.min-height-400 {
	min-height:400px;
}

.box {
	box-shadow:0 0 8px 1px rgba(228,232,238,.25);
	border:1px solid #E4E8EE;
	background:#fff;
	padding:30px;
}

@media screen and (min-width:@breakpoint-medium) {
	.minus-100 {
		margin-top:-100px;
	}

	.minus-150 {
		margin-top:-150px;
	}

	.minus-200 {
		margin-top:-200px;
	}

	.bottom-minus-100 {
		margin-bottom:-100px;
	}
}

.padding-top-40 {
	padding-top:4px;
}

.padding-bottom-130 {
	padding-bottom:130px;
}

h2.lead {
	font-size:38px;
}
#hero {
	
	height:40vh;
	padding-top: 120px;

	.uk-flex {
		@media screen and (min-height: 600px) {
			height:40vh;
		}
	}

	h1 {
		font-size:26px
	}

	br {
		display:none
	}

	@media screen and (min-width: 787px) {
		p {
			font-size:18px;
			font-weight:300
		}

		h1 {
			font-size:38px;
			position:relative;
			z-index:100
		}

		br {
			display:block
		}
	}

	h3 {
		position:relative;
		z-index:100
	}
}

html.fb-subpage {

	#hero {
		height:200px;
	}
	.is-bg-dark {

		padding-bottom:0;

	    h1 {
	    	margin-bottom:0
	    }

	    .is-boxes {
	    	z-index:10
	    }
	}
}

.uk-breadcrumb {
	h1,h2 {
		color:#fff !important;
		font-size:30px;
		text-transform:uppercase;
	}
}

footer {
	border-top:1px solid rgba(0,0,0,0.06);
	clear:both;

	a {
		color:@text-color;
		text-transform:uppercase;
		font-size:12px;
		color:#666
	}
}

.btn.btn-blue {
	background: @fblue;
	color:#fff;
}

.tm-price-column {
	border:1px solid rgba(0,0,0,0.06);
    box-shadow: 1px 1px 0 0 #f3f3f3, inset 1px 0 #f3f3f3;
    position: relative;
}

.tm-price-header {
    background-color: lighten(@blue,5%);
    color: #fff;
    width:100%;

    * {
    	color:#fff
    }
    padding: 30px 0 20px;
    z-index: 1;
     position: relative;
}

.fb-blog,.white-header{
    
    background: #fafafa;

	header {
		background: #fff;
        border-bottom:1px solid #eee;
        background:#fff
	}

	article {
		background:#fff;
		box-shadow:0 2px 2px rgba(0,0,0,0.06);
	}

	ul#nav li a {
		color:@text-color;

		&:hover {
			color:@fblue;
		}
	}

	.uk-article-title a {
		color:@text-color;
		&:hover {
			text-decoration:none;
			color:rgb(164, 53, 64);
		}
	}

	.btn.btn-primary {
		background:rgb(164, 53, 64);

		&:hover {
			background:darken(rgb(164, 53, 64), 8%)
		}
	}

	footer {
		background:#fff;
	}

	#hero {
		min-height:100px !important;
		height:100px;
	
		h1 {
			margin-top:-100px !important;
			font-size:6em
		}
	}

	.uk-container {
		z-index:10;
		position:relative;
		width:90%;
	}

	.blog-header {
		background: @fblue
	}

	.overlay {
	    position: fixed;
	    top: 0;
	    right: 0;
	    background: rgba(0,0,0,0.4);
	    width: 100%;
	}
}

.white-header {
    background:#fff;

    .is-wrapper {
        padding-top:100px;
    }

    .logo {
        margin-left:0 !important
    }

    .mobile-btn {
        color:@text-color
    }
}

.blue-header {
    .logo {
        margin-left:0 !important
    }
}

article {
	float:left;
	width:100%
}

.fb-default-overlay {
	.fb-background-overlay {
		position: absolute;
		z-index:1;
	    height: 100%;
	    width: 100%;
	    background: fade(@blue,80%);
	    border-radius:3px;
	}

	&:hover {
		.fb-background-overlay {
			 background: fade(#000,60%);
			 transition: 0.3s all
		}
	}
}

.fb-image-overlay {
	.fb-background-overlay {
		position: absolute;
		z-index:1;
	    height: 100%;
	    width: 100%;
	    background: fade(#000,40%);
	    border-radius:3px;
	}

	&:hover {
		.fb-background-overlay {
			 background: fade(#000,60%);
			 transition: 0.3s all
		}
	}
}

.uk-background-cover {
	border-radius:3px;
	position:relative;

}

.uk-overlay {
	z-index:10;
}

#header {
	padding:15px 0;
}

img.shadow {
	box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
	border: 10px solid #fff;
 }

#features {
	img {
		box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
    	border: 10px solid #fff;

    	@media screen and (min-width: 960px) {
    		position: absolute;
    	}
	}
}

.z-index-10 {
	z-index:10;
}

.grey-row {
	background:#fafafa;
	margin-bottom:-60px;;
	padding:80px 0;
}

.dark-row {
	background:#15161B;
	padding:80px 0;

	h2 {
		color:#999;
	}

	.btn {
		background:rgba(0,0,0,0.6);

	}

	&:after {
	   	background-color:darken(#15161B, 0%);
	   	opacity:0.96;
	}
}

.blue-row {
	padding:80px 0;

	&:after {
           background-color:fade(@fblue, 90%);
           background-color: rgba(62, 144, 209, 0.9);
           content: "";
           height: 100%;
           width: 100%;
           position: absolute;
           top: 0;
           z-index: 100;
	}
}

.green-row {
	background:#55AB67;
	padding:80px 0 20px;

	h2,strong {
		color:#fff;
	}

	 &:after {
	   	background-color:darken(#55AB67, 0%);
	   	opacity:0.96;
	   }
}

.inner-wrap {
	position:relative;
	z-index:10;
}

.white-row {
	background:#fff;
	padding:80px 0;

	 &:before,
	  &:after {
	    background: inherit;
	    content: '';
	    display: block;
	    height: 50%;
	    left: 0;
	    position: absolute;
	    right: 0;
	    z-index: -1;
	    -webkit-backface-visibility: hidden; // for Chrome Windows
	  }

	  &:before {
	    top: 0;
	    transform: skewY(1.5deg);
	    transform-origin: 100% 0;
	  }
	  
	  &:after {
	    bottom: 0;
	    transform: skewY(-1.5deg);
	    transform-origin: 100%;
	  }
}

p.uk-text-lead {
	color:#838C91;
	font-weight:300
}

/* ------------------------------------------------------------------ */
/* i. Subscribe Section
/* ------------------------------------------------------------------ */

#subscribe {
   background: @fblue;
   padding-top: 96px;
   padding-bottom: 66px;
   overflow: hidden;
   text-align: center;

   &:after {
   	background-color:darken(@fblue, 20%);
   	opacity:0.96;
   }
}

.subscribe-inner {
	position:relative;
	z-index: 10
}

#subscribe a, #subscribe a:visited  { color: #fff; }
#subscribe a:hover, #subscribe a:focus { color: #11ABB0; }

#subscribe h2 { color: #fff; }
#subscribe p {
   color: #fff;
   text-align: center;
   padding: 0;
}

/* mailchimp signup form */
#mc_embed_signup {
   background: none;
   clear: both;
   display: block;
   margin: 6px auto 0 auto;
   width: 70%;
  	text-align: center;
}
#mc_embed_signup:before,
#mc_embed_signup:after {
    content: " ";
    display: table;
}
#mc_embed_signup:after {
    clear: both;
}
#mc_embed_signup input[type="submit"] {
   float: left;
   height: 64px;
   margin: 0;
   padding: 0 20px;
   font-size: 12px;
   line-height: 60px;
   width:100%;
   cursor:pointer;
   -webkit-appearance: none;
   @media screen and (min-width:787px) {
   	width: 30%;
   }
   background:rgba(0,0,0,0.4);
   border:0;
   color:#fff;
   text-transform:uppercase;
   &:hover {
   	background:rgba(0,0,0,0.25)
   }
}
#mc_embed_signup input[type="email"] {
   display: inline;
   float: left;

   margin: 0 0 0 30px;
   padding: 14px 20px;
   font-size: 12px;
   border:0;
   text-align:left;

   line-height: 24px;
   font-family: 'montserrat-regular',sans-serif;
   width:100%;
   @media screen and (min-width:787px) {
   	width: 63%;

   }
   padding:10px;
   line-height:42px;
   display:block;
   box-sizing:border-box;
   text-align: center;
}

.btn {
	font-weight:300;
	background: #f5f5f5;
	color:@text-color;
	font-size:12px;
	letter-spacing: .1rem;
	box-shadow:none;
	border:0;
	
	&.btn-primary,
	&.uk-button-primary,
	&:hover {
		background:@brightblue;
		color:#fff;
	}

	&.active {
		box-shadow:none;
	}

	&.small{
		font-size:12px
	}

	&.btn-trans {
		background:rgba(0,0,0,0.1) !important;
	}

	&.btn-red {
		background:@red;
		color:#fff;
	}

	&.uk-button.btn-outline {
		border:1px solid @text-color;
    }
    
    

	&.btn-outline {
		background:none;

		&:hover {
			background:@brightblue;
			border-color:darken(@brightblue,8%);
			color:#fff;
		}
	}
}

#sidebar {

	li {
		border-bottom:1px solid rgba(0,0,0,0.06);
		padding-bottom:10px;
	}
	a {
		color:@text-color;
	}
}

@media screen and (max-width: 787px) { 

	.uk-text-lead {
		font-size:1.2rem !important
	}
	
}

a.text-color {
	color:@text-color
}

#demo-top {
	background: #fff;
    border-bottom: 1px solid rgba(0,0,0,0.04);
    position:relative;
    z-index:10;
}
#demo-frame {
	margin:0 auto;
	width:100%;
	transition: all 0.3s;
	box-shadow:0 0 100px rgba(0,0,0,.22);
	padding:0px !important;
	&[data-width="phone"] {
		width:400px;
		margin:40px auto;
		max-height:736px;
		overflow:hidden;
		height:736px;
		min-height:0 !important;
	}

	&[data-width="tablet"] {
		width:787px;
		margin:40px auto;
		max-height:1024px;
		overflow:hidden;
		height:1024px;
		min-height:0 !important;
	}
}

.frame-resize {

	&:hover {
		color:#999
	}
	&.active {
		color:@fblue !important
	}
}

.screen {
	background:#fff;
}

[data-type="tablet-design"] {
	.ipad {
	  position: relative;
	  background: #fafafa;
	  border:1px solid rgba(0,0,0,0.06);
	  margin-top:30px;
	  width: 80%;
	  height: 460px;
	  padding-right:60px;
	  padding-top: 70%;
	  margin: 60px auto 4em auto;
	  border-radius: 3em;
	}

	.camera {
	  position: absolute;
	  top: 4%;
	  left: 49.25%;
	  background: #3f3f3f;
	  width: 1.5%;
	  height: 0;
	  padding-top: 1.5%;
	  border-radius: 50%;
	}

	.appleicon {
	  padding-top: 25%;
	  opacity: 0;
	  animation: unhide 5s infinite alternate;
	}

	.appleicon img{
	  width: 100%;
	  height: 100%;
	}

	.text {
	  opacity: 0;
	  font-size: 1.25rem;
	  text-align: center;
	  animation: unhide 5s infinite alternate;
	}

	.screen {
	  width: 96%;
	  height: 96%;
	  position: absolute;
	  top: 10%;
	  left: 2%;
	  overflow:hidden;
	}

	.button {
	  position: absolute;
	  bottom: 1.5%;
	  left: 46%;
	  background: #3f3f3f;
	  /* border: 1px solid #3f3f3f; */
	  width: 7%;
	  height: 0;
	  padding-top: 7%;
	  border-radius: 50%;
	}

	.square {
	  position: absolute;
	  top: 27.5%;
	  left: 27.5%;
	  border: 2px solid #d7d7d7;
	  border-radius: .3em;
	  width: 45%;
	  height: 45%;
	}
}

#blog {
    .content-row {
        padding-left:30px;
        padding-right:30px;
    }
}

.bg-white,.content-row {
    background: #fff;
}

.bg-grey {
    background: #fafafa
}

.bg-dark {
    background: #323232
}

.content-row {background-size:cover !important;}
.height-auto { height:auto;}
.height-20 { min-height:20vh}
.height-30 { min-height:30vh}
.height-40 { min-height:40vh}
.height-50 { min-height:50vh}
.is-section-50 { min-height:50vh}
.height-60 { min-height:60vh}
.height-70 { min-height:70vh}
.height-80 { min-height:80vh}
.height-90 { min-height:90vh}
.height-100 { min-height:100vh}
.height-100px {min-height:100px}
.height-200px {min-height:200px}
.height-300px {min-height:300px}
.height-400px {min-height:400px}
.height-500px {min-height:500px}
.height-600px {min-height:600px}
.height-700px {min-height:700px}
.height-800px {min-height:800px}
.uk-container {width:96%;}
// .uk-container-full {max-width:90%;}
// .uk-container {width:1200px;}
// .uk-container-large {width:1600px;}
.uk-container > .uk-grid {position:relative;z-index:2;}
.padding-none {padding:0;}
.padding-small {padding:15px 0;}
.padding-medium {padding:30px 0;}
.padding-large {padding:60px 0;}
.padding-extra-large {padding:100px 0;}

.content-row-overlay {position:absolute;top:0;bottom:0;height:100%;width:100%;z-index: 1}
.overlay-black .content-row-overlay {background:#000}
.overlay-white .content-row-overlay {background:#fff}
.overlay-dark .content-row-overlay {background:fade(#000, 50%)}
.overlay-darkest .content-row-overlay {background:fade(#000, 75%)}
.overlay-darkest .content-row-overlay {background:fade(#000, 75%)}
.overlay-dark-95 .content-row-overlay {background:fade(#000, 95%)}
.overlay-light .content-row-overlay {background:fade(#fff, 50%)}
.overlay-lightest .content-row-overlay {background:fade(#fff, 75%)}
.overlay-light-95 .content-row-overlay {background:fade(#fff, 95%)}
.overlay-none .content-row-overlay {display:none;}

.bg-position-top {background-position: top center}
.bg-position-right {background-position: right center}
.bg-position-bottom {background-position: bottom center}
.bg-position-left {background-position: left center}
.bg-position-center {background-position: center center}
.bg-position-fixed {background-attachment:fixed !important;}

.spacer-block > div {
	min-height: 120px
}

figure:focus {
	outline:none
}

.content-block {
	h1 {
		margin-top:0 !important
	}
}

.content-block {p {margin-top:0 !important}}
ol {
	li {
		list-style-type:decimal
	}
}

.content-row {
	.uk-container {
		position:relative;
		z-index:2;
	}
}

.mobile-menu {
    a {
        color:@text-color;
        font-size:16px;
        font-weight:bold
    }
}

//
// Component:       Variables
// Description:     Defines common values which are used across all components
//
// ========================================================================

// Breakpoints
// ========================================================================

// Phone Portrait:   Galaxy (360x640), iPhone 6 (375x667), iPhone 6+ (414x736)
// Phone Landscape:  Galaxy (640x360), iPhone 6 (667x375), iPhone 6+ (736x414)
// Tablet Portrait:  iPad (768x1024), Galaxy Tab (800x1280),
// Tablet Landscape: iPad (1024x768), iPad Pro (1024x1366),
// Desktop:          Galaxy Tab (1280x800), iPad Pro (1366x1024)

@breakpoint-small:                             640px;  // Phone landscape
@breakpoint-medium:                            960px;  // Tablet Landscape
@breakpoint-large:                             1200px; // Desktop
@breakpoint-xlarge:                            1600px; // Large Screens

@breakpoint-xsmall-max:                        (@breakpoint-small - 1);
@breakpoint-small-max:                         (@breakpoint-medium - 1);
@breakpoint-medium-max:                        (@breakpoint-large - 1);
@breakpoint-large-max:                         (@breakpoint-xlarge - 1);

// Global variables
// ========================================================================

//
// Typography
//

@global-font-family:                            -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
@global-font-size:                              14px;
@global-line-height:                            1.5;      // 24px

@global-xxlarge-font-size:                      2.625rem; // 42px
@global-xlarge-font-size:                       2rem;     // 32px
@global-large-font-size:                        1.5rem;   // 24px
@global-medium-font-size:                       1.25rem;  // 20px
@global-small-font-size:                        0.875rem; // 14px

//
// Colors
//

@global-color:                                  #666;
@global-emphasis-color:                         #333;
@global-muted-color:                            #999;

@global-link-color:                             #1e87f0;
@global-link-hover-color:                       #0f6ecd;

@global-inverse-color:                          #fff;

//
// Backgrounds
//

@global-background:                             #fff;

@global-muted-background:                       #f8f8f8;
@global-primary-background:                     #1e87f0;
@global-secondary-background:                   #222;

@global-success-background:                     #32d296;
@global-warning-background:                     #faa05a;
@global-danger-background:                      #f0506e;

//
// Borders
//

@global-border-width:                           1px;
@global-border:                                 #e5e5e5;

//
// Box-Shadows
//

@global-small-box-shadow:                       0 2px 8px rgba(0,0,0,0.08);
@global-medium-box-shadow:                      0 5px 15px rgba(0,0,0,0.08);
@global-large-box-shadow:                       0 14px 25px rgba(0,0,0,0.16);
@global-xlarge-box-shadow:                      0 28px 50px rgba(0,0,0,0.16);

//
// Spacings
//

// Used in margin, section, list
@global-margin:                                 20px;
@global-small-margin:                           10px;
@global-medium-margin:                          40px;
@global-large-margin:                           70px;
@global-xlarge-margin:                          140px;

// Used in grid, column, container, align, card, padding
@global-gutter:                                 30px;
@global-small-gutter:                           15px;
@global-medium-gutter:                          40px;
@global-large-gutter:                           70px;

//
// Controls
//

@global-control-height:                         40px;
@global-control-small-height:                   30px;
@global-control-large-height:                   55px;

//
// Z-index
//

@global-z-index:                                1000;